<template>
    <MeasurementItem>
        <template #content>
            <div v-if="isLoading" class="no-padding l-full-width">
                <VSpinner size="medium" line-fg-color="black" :speed="1" />
            </div>

            <div v-else class="l-full-width">
                <ApexChart
                    :options="chartOptions"
                    :series="series"
                    :height="chartHeight"
                />
            </div>
        </template>
    </MeasurementItem>
</template>

<script>
import moment from 'moment'
import ApexChart from 'vue-apexcharts'
import VSpinner from 'vue-simple-spinner'

import { httpHelper } from '../utils'
import MeasurementItem from './MeasurementItem'
import MeasurementListHelper from '../mixins/MeasurementListHelper'

const activitiesToDisplay = [3, 2, 8, 0]

export default {
    name: 'TruckTimelineMeasurementFragment',
    components: {
        ApexChart,
        MeasurementItem,
        VSpinner,
    },
    mixins: [MeasurementListHelper],
    data() {
        return {
            activityTranslations: activitiesToDisplay.reduce(
                (acc, cur) => ({
                    ...acc,
                    [cur]: this.$t(`shared.tachograph.working_state.${cur}`),
                }),
                {}
            ),
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                    zoom: {
                        enabled: false,
                    },
                    toolbar: {
                        show: false,
                    },
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '50%',
                        rangeBarGroupRows: true,
                    },
                },
                colors: ['#d32f2f', '#00a1de', '#fdae61', '#41b883'],
                xaxis: {
                    type: 'datetime',
                    min: moment()
                        .startOf('day')
                        .valueOf(),
                    max: moment()
                        .add(1, 'day')
                        .startOf('day')
                        .valueOf(),
                    labels: {
                        datetimeUTC: false,
                    },
                },
                yaxis: {
                    showAlways: true,
                    showForNullSeries: false,
                    labels: {
                        formatter: value => {
                            return typeof value === 'string' ? value : ''
                        },
                    },
                },
                tooltip: {
                    x: {
                        format: 'HH:mm',
                    },
                },
            },
            isLoading: true,
            series: [],
        }
    },
    computed: {
        chartHeight() {
            let xFirst
            const isMultiSeries = !this.series.every(({ data }) =>
                data.every(({ x }) => {
                    if (x && !xFirst) {
                        xFirst = x
                    }
                    return x === xFirst
                })
            )
            return isMultiSeries ? 200 : 140
        },
    },
    watch: {
        'sensorData.driver_id': {
            immediate: true,
            async handler(newDriverId, oldDriverId) {
                if (newDriverId?.value === oldDriverId?.value) {
                    return
                }
                try {
                    this.isLoading = true
                    const { data } = await httpHelper.getRecursively(
                        '/tachograph/driver-activities/',
                        {
                            params: {
                                driver_id: newDriverId?.value,
                                start: moment()
                                    .subtract(1, 'day')
                                    .startOf('day')
                                    .format(),
                                end: moment()
                                    .endOf('day')
                                    .format(),
                            },
                        }
                    )

                    const today = new Date().toJSON().slice(0, 10)
                    const activities = data.results.reduce((acc, cur) => {
                        const startDay = cur.start.slice(0, 10)
                        const endDay = cur.end.slice(0, 10)
                        return startDay === endDay
                            ? [...acc, cur]
                            : [
                                  ...acc,
                                  {
                                      ...cur,
                                      end: `${startDay}T23:59:59${cur.start.slice(
                                          19
                                      )}`,
                                  },
                                  {
                                      ...cur,
                                      start: `${endDay}T00:00:00${cur.end.slice(
                                          19
                                      )}`,
                                  },
                              ]
                    }, [])

                    this.series = activitiesToDisplay.map(activity => ({
                        name: this.activityTranslations[activity] || activity,
                        data: activities
                            .filter(
                                ({ activity_number }) =>
                                    activity_number === activity
                            )
                            .map(({ start, end }) => ({
                                x:
                                    start > today
                                        ? this.$t('today')
                                        : this.$t('yesterday'),
                                y: [
                                    new Date(today + start.slice(10)).getTime(),
                                    new Date(today + end.slice(10)).getTime(),
                                ],
                            })),
                    }))
                } finally {
                    this.isLoading = false
                }
            },
        },
    },
}
</script>

<i18n>
{
    "en": {
        "today": "Today",
        "yesterday": "Yesterday"
    },
    "de": {
        "today": "Heute",
        "yesterday": "Gestern"
    },
    "fr": {
        "today": "Aujourd'hui",
        "yesterday": "Hier"
    },
    "it": {
        "today": "Oggi",
        "yesterday": "Ieri"
    }
}
</i18n>
